import React from 'react'
import { motion,useInView,useAnimation } from "framer-motion/dist/framer-motion";
import { useRef,useEffect } from "react";

function About(){
    const ref2=useRef(null);
    const isInView=useInView(ref2,{once:true,threshold:0.5})
    const AboutControllers=useAnimation();
    useEffect(()=>{
        if(isInView){
            AboutControllers.start("visible");
        }
    })
    const AboutVariants={
        hidden:{
            opacity:0,
            y:"50vh"
        },
        visible:{
            opacity:1,
            y:0,
            transition:{
                type:"spring",
                duration:0.1,
                stiffness:40
            }
        }
    }
    return(
        <section id="about-us" ref={ref2}>
            <motion.div 
                variants={AboutVariants}
                initial="hidden"
                animate={AboutControllers}
                class="container-fluid">
                <div class="row selection ">
                    <div class="col-lg-8">
                        <p class="wow fadeInUp" data-wow-delay="0.4s">
                            Don't wait for the perfect moment to shop for Volkswagen parts. The time is now. Shop now and get the parts you need.
                        </p>

                        <div class="link wow fadeInUp" data-wow-delay="0.3s">
                            <a href="">learn now
                                <iconify-icon icon="gridicons:arrow-right"></iconify-icon>
                            </a>
                        </div>
                    </div>
                </div>
            </motion.div>

            <div class="container-fluid about">
                <div class="row some-cards">
                    <p id="prod-desc">
                        At DSG Customs, we are passionate about cars and committed to providing the best car modification and spare parts to our customers. Our team of experts has years of experience in the automotive industry and is dedicated to delivering top-quality products and exceptional customer service.
                        <br/>
                        We believe that every car enthusiast deserves to have their dream ride, and that's why we offer a wide selection of car modification parts and accessories to upgrade your vehicle's performance and style. Our products are carefully selected from leading brands and tested for quality and durability, ensuring that you get the best value for your investment.
                        <br/>
                        At DSG Customs, we are more than just a car modification parts supplier. We are a community of car enthusiasts who share the same passion for cars and the thrill of the ride. We are always happy to share our knowledge and expertise with our customers and help them achieve their dream ride.
                        <br/>
                        Contact us today and let us help you unleash the full potential of your ride with our exclusive collection of car modification parts and accessories.
                    </p>
                </div>
            </div>
        </section>
    )
}

export default About;