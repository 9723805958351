import React from 'react'
import { motion } from "framer-motion/dist/framer-motion";
function Icons(){
    return(
        <ul>
            <motion.li whileHover={{scale:1.3,originX:0}}  ><iconify-icon icon="mdi:instagram"></iconify-icon></motion.li>
            <motion.li whileHover={{scale:1.3,originX:0}}  ><iconify-icon icon="mdi:youtube"></iconify-icon></motion.li>
            <motion.li whileHover={{scale:1.3,originX:0}}  ><iconify-icon icon="mdi:facebook"></iconify-icon></motion.li>
            <motion.li whileHover={{scale:1.3,originX:0}}  ><iconify-icon icon="mdi:whatsapp"></iconify-icon></motion.li>
        </ul>
    )
}
export default Icons;