import React, { useState } from "react";
import { animate, AnimatePresence, motion, useAnimation } from "framer-motion/dist/framer-motion";
import car from "../../Images/Subject.png"
import logo from "../../Images/logo.png"

function CarAnimation({ onComplete }) {
    const [isAnimation1Complete, setIsAnimation1Complete] = useState(false);

    const controls = useAnimation();

    const startAnimation=() => {
        controls.start({
            x: 1120, // Animate to the right by 100px
            transition: { duration: 0.5 }, // Animation duration
        });
    }

    const handleAnimation1Complete = () => {
        setIsAnimation1Complete(true);
    };
    const handleAnimation2Complete = () => {
        startAnimation();
    };

    return (
        <motion.div 
        className="animation-1"
        initial={{ x: 0 }}
        animate={controls}
        onAnimationComplete={()=>{onComplete()}}
        >
            <motion.div
                className="moving-picture"
                initial={{ y: -1000 }}
                animate={{ y: 0 }}
                transition={{
                    duration: 0.5,
                    ease: "linear",
                }}
                onAnimationComplete={handleAnimation1Complete}
                >
                <img src={logo} alt="Moving Picture" />
            </motion.div>
            {isAnimation1Complete && (<motion.div
                className="moving-picture1"
                initial={{ x: -1500 }}
                animate={{ x: 0 }}
                transition={{
                    duration: 0.5,
                    ease: "linear",
                }}
                onAnimationComplete={handleAnimation2Complete}
                >
                <img src={car} alt="Moving Picture" />
            </motion.div>)}
            
        </motion.div>

    );
}
export default CarAnimation;