import React from 'react'
import Carousel from "./Carousel";
function Product(props) {
    const {details}=props;
    const pid=details.id;
    const price=details.price;
    const images=details.images;
    const name=details.name;
    const id="carousel"+pid;

    return (
        <div >
            <p id="price">INR {price}</p>
            <div className="prod-img" >
              <Carousel id={id} images={images} ></Carousel>
            </div>
            <a href="" className="d-block mx-auto" style={{width:"fit-content"}}>
              <p id="prod-desc">{name}</p> 
            </a>   
        </div>
    );
  }
  
  export default Product;
  
