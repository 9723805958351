import React from 'react'
import { useEffect, useRef, useState } from "react";
import Carousel from "../Components/utils/Carousel";
import { FlatTree, useInView } from "framer-motion/dist/framer-motion";
import Navbar from "../Components/utils/Navbar";
import Footer from "../Components/utils/Footer";
import axios from "axios";
import CarAnimation from "../Components/utils/CarAniamation";


function Products(){
    const ref=useRef(null);
    const isInView=useInView(ref,{once:true});
    const [IsAnimation,setIsAnimationComplete]=useState(false);
    const [searchInput,setSearchInput]=useState('');
    const [searchedString,setSearchString]=useState("");
    const [data,setData]=useState([]);
    const [products,setProducts]=useState([]);
    const [isSearch,setSearch]=useState(false);
    useEffect(() => {
        // Fetch products after the animation has completed
        if (IsAnimation) {
            axios
                .get("https://dsg-customs-backend.vercel.app/api/getProducts")
                .then((response) => {
                setData(response.data);
                setProducts(response.data);
                })
                .catch((err) => {
                console.log(err);
                });
        }
    }, [IsAnimation]);
    useEffect(() => {
        // Initialize the carousel when the data is fetched
        data.forEach(product => {
            const carousel = new window.bootstrap.Carousel(document.getElementById(product.id), {
                interval: 4000,
            });
    
            // Clean up carousel when component unmounts
            return () => {
                carousel.dispose();
            };
        });
    }, [data]); // Make sure to include data as a dependency    

    function handleSearch(){
        const temp=[];
        if(searchInput!=""){
            const searchKey=searchInput.toLowerCase();
            data.filter(product=> product.name.toLowerCase().includes(searchKey)).map(filteredProduct=>{
                temp.push(filteredProduct);
            })
            setProducts(temp); 
            setSearchString(searchInput);
            setSearch(true);
        }
        else{
            setProducts(data);
            setSearchString(searchInput);
            setSearch(false);
        }

    }

    function handleBack(){
        setSearchInput("");
        setProducts(data);
        setSearch(false);
        setSearchString('');
    }

    function handleKeyDown(event){
        if (event.key === 'Enter'){
            handleSearch();
        }
    }

    return(
        <div style={{ background: IsAnimation ? "white" : "linear-gradient(to right, black 0px, black 33.33%, white 33.33%, white 66.66%, #e96a0f 66.66%, #e96a0f)" }}>
            {!IsAnimation && (<CarAnimation onComplete={()=>setIsAnimationComplete(true)}/>)}
            { IsAnimation &&
            (<div ref={ref}>
                <Navbar/>
                <div className="shop" >
                <div className="mb-5">
                    <h1 className="shop-h1">DSG Customs Shop: Upgrade Your Ride Today</h1>
                    <h3 className="shop-h1" style={{fontSize:"1.5rem"}}>
                        Unleash the Power of Your Ride with Our Exclusive Collection
                    </h3>
                </div>
                    <div className="search-bar">
                        <input className="search-bar-input" type="text"  onKeyDown={handleKeyDown} onChange={(e) => { setSearchInput(e.target.value) }} value={searchInput} />
                        <button className="search-button" onClick={handleSearch}>Go</button>
                    </div>
                    { isSearch && (<div style={{marginBottom:"5%"}}>
                        <iconify-icon icon="fluent-mdl2:back" class="shop-back-icon" onClick={handleBack} ></iconify-icon>
                        <h1 className="d-inline" style={{marginLeft:"3%",color:"white"}}>Search results for "<h1 className="d-inline" style={{color:"#e96a0f"}}>{searchedString}</h1>"</h1>
                    </div>)}

                    {products.map((product)=>(
                        <div className="row shop-product">
                            <div className="col-lg-4 col-sm-4 col-4 product-image">
                                <Carousel id={product.id} images={product.images} />
                            </div>
                            <div className="col-lg-6 col-sm-6 col-6 ">
                                <h2 className="product-name">{product.name}</h2>
                                <div className="rating">
                                    <p>4.3</p> 
                                    <iconify-icon icon="ph:star-fill" style={{color:"white",backgroundColor:"green"}}></iconify-icon>
                                    
                                </div>
                                <h1 className="product-price">{'₹'+product.price}</h1>
                                <p className="product-description">{product.description}</p>
                            </div>
                            <div className="col-lg-2 col-sm-2 col-2 buy">
                                <button className="buy-now-button"  onClick={() => {window.location.href = 'https://checkout.stripe.dev/preview';}}><p>Buy Now</p></button>
                                <button className="add-to-cart invisible"><iconify-icon icon="mdi:cart" style={{marginRight:"4%"}}></iconify-icon>Add to cart</button>
                            </div>
                        </div>
                    ))}
                </div>
                <Footer/>
            </div>)}
        </div>
    )
}

export default Products;
