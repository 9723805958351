import React from 'react'
import Navbar from '../Components/utils/Navbar';
import Hero from '../Components/Hero'
import Product from '../Components/utils/Product';
import Banner from '../Components/Banner';
import About from "../Components/utils/About";
import { useEffect, useRef, useState } from 'react';
import {AnimatePresence, motion, useInView,useAnimation} from "framer-motion/dist/framer-motion";
import Contact from '../Components/Contact';
import Footer from '../Components/utils/Footer';
import axios from 'axios';
import { useParams } from 'react-router-dom';

function Home() {
  
  const ref1=useRef(null);
  const isHomeInView=useInView(ref1,{once:true});
  const [products,setProduct]=useState([]);
  const [isRecieved,setRecieved]=useState(false);
  const { sectionId } = useParams();

  useEffect(() => {
    const targetSection = document.getElementById(sectionId);
    if (targetSection) {
      setTimeout(() => {
        targetSection.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    }
  }, [sectionId]);

  useEffect(()=>{
    if(isHomeInView){
      axios.get("https://dsg-customs-backend.vercel.app/api/getProducts")
    .then(product=>{
      setProduct(product.data)
      setRecieved(true);
    })
    .catch(err=>{
      console.log(err);
    })
    }
  },[isHomeInView]);

  const ref=useRef(null);
  const isInView=useInView(ref,{once:true});
  const mainControls=useAnimation();
  useEffect(() => {
    if(isInView){
      mainControls.start("after");
    }
  },[isInView,isRecieved]);

  const sectionVariants={
    before:{
      opacity:0,
      y:"10vw"
    },
    after:{
      opacity:1,
      y:0,
      transition:{
        delay:0,
        duration:0,
        when:"beforeChildren",
        staggerChildren:0.1
      }
    }

  }
  const productVariants={
    before:{
      opacity:0,
      y:"100vw"
    },
    after:{
      opacity:1,
      y:0,
      transition:{
        duration:0.5,
        type:"spring",
        stiffness:50
      }
    }
  }
  return (
    <div ref={ref1}>
      <Navbar page="home"/>
      <Hero/>
      <div className="section prod-list">
        <motion.div 
        variants={sectionVariants}
          initial="before"
          animate={mainControls}
        ref={ref}
        >
            <div class="container-fluid">
                <div class="row some-cards">
                  {products.slice(0,4).map((product, index) => (
                    <motion.div 
                    variants={productVariants}
                    className='col-lg-3 col-md-6 col-sm-6 col-6 prod'>
                      <Product key={index} details={product}></Product>
                    </motion.div>
                  ))}
                </div>
            </div>
        </motion.div>
        <Banner/>
        <About/>
      </div> 
      <Contact/>
      <Footer/>
    </div>
  );
}

export default Home;
