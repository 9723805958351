import React from 'react'
import axios from "axios";
import { useState ,useRef} from "react";
import Alert from "../Components/utils/Alert";
import AdminNavbar from "../Components/utils/AdminNavbar";

function EmailForm(){
    const [message,setMessage]=useState("")
    const [isAlertOpen,setAlertOpen]=useState(false);
    const formRef=useRef(null);

    function handleSubmit(event){
        event.preventDefault();
        const formData=new FormData(event.currentTarget);
        const data =Object.fromEntries(formData);
        axios.post("https://dsg-customs-backend.vercel.app/send-email",data)
        .then(response=>{
            setMessage(response.data);
            setAlertOpen(true);
            if(response.status===200){
                formRef.current.reset();
            }
        })
    }
    return(
        <div>
            <AdminNavbar/>
            <h1>Email Form</h1>
            <form id="emailForm" ref={formRef} onSubmit={handleSubmit}>
                <label for="customer_name">Customer Name:</label>
                <input type="text" name="customer_name" id="customer_name" required/><br/>

                <label for="customer_email">Customer Email:</label>
                <input type="email" name="customer_email" id="customer_email" required/><br/>

                <label for="order_id">Order ID:</label>
                <input type="text" name="order_id" id="order_id" required/><br/>

                <label for="order_date">Order Date:</label>
                <input type="date" name="order_date" id="order_date" required/><br/>

                <label for="order_total">Order Total:</label>
                <input type="text" name="order_total" id="order_total" required/><br/>

                <button type="submit">Send Email</button>
            </form>
            {isAlertOpen && (<Alert
            isCancel={false}
            message={message}
            onCancel={()=>{setAlertOpen(false)}}
            onConfirm={()=>{setAlertOpen(false)}}
            />)}
        </div>
    )
}

export default EmailForm;