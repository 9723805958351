import React from 'react'
import axios from "axios";
import { useEffect,useState } from "react";
import AddProduct from "../Components/AddProduct";
import Alert from "../Components/utils/Alert";
import AdminNavbar from "../Components/utils/AdminNavbar";
function Add(){
    const isNew=true;
    const [products,setProducts]=useState([])
    const [isChange,setIsChange]=useState(true);
    const [isAlertOpen, setAlertOpen] = useState(false);
    const [isDeleted, setDeleted] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [searchInput,setSearchInput]=useState('');
    const [searchedString,setSearchString]=useState("");
    const [localProducts,setLocalProducts]=useState([]);
    const [isSearch,setSearch]=useState(false);
    const tempArray={name:"",description:"",quantity:"",price:"",images:[]};
    useEffect(()=>{
        axios.get("https://dsg-customs-backend.vercel.app/api/getProducts")
        .then((response)=>{
            setProducts(response.data);
            setLocalProducts(response.data);
            setIsChange(false);
        })
    },[isChange]);


    function deleteProduct(index) {
        setDeleteIndex(index); // Set the index of the product to be deleted
        setAlertOpen(true); // Open the alert
    }

    function handleCancel() {
        setDeleteIndex(null); // Reset the delete index
        setAlertOpen(false);
    }

    function handleConfirm() {
        setAlertOpen(false);
        if (deleteIndex !== null) {
            axios.delete(`https://dsg-customs-backend.vercel.app/api/deleteProduct/${products[deleteIndex].id}`)
            .then(response=>{
                if(response.data==="deleted"){
                    setDeleted(true);
                    setIsChange(true);
                }
            })
        }

        setDeleteIndex(null); // Reset the delete index
        setDeleted(false);
    }

    function handleSearch(){
        const temp=[];
        if(searchInput!=""){
            const searchKey=searchInput.toLowerCase();
            products.filter(product=> product.name.toLowerCase().includes(searchKey)).map(filteredProduct=>{
                temp.push(filteredProduct);
            })
            setLocalProducts(temp); 
            setSearchString(searchInput);
            setSearch(true);
        }
        else{
            setLocalProducts(products);
            setSearchString(searchInput);
            setSearch(false);
        }

    }

    function handleBack(){
        setSearchInput("");
        setLocalProducts(products);
        setSearch(false);
        setSearchString('');
    }

    function handleKeyDown(event){
        if (event.key === 'Enter'){
            handleSearch();
        }
    }

    return(
        <div>
            <AdminNavbar/>
            <div className="admin-page">
            <h1 className="admin-h1" >ADD PRODUCTS</h1>
            <AddProduct product={tempArray} isNew={isNew} setIsChange={setIsChange} />
            <div>
                <h1 className="admin-h1"> UPDATE PRODUCTS</h1>
                <div className="search-bar">
                    <input className="search-bar-input" style={{borderStyle:"groove",height:"7vh"}} type="text"  onKeyDown={handleKeyDown} onChange={(e) => { setSearchInput(e.target.value) }} value={searchInput} />
                    <button className="search-button" style={{height:"7vh"}} onClick={handleSearch}>Go</button>
                </div>
                { isSearch && (<div style={{marginBottom:"5%"}}>
                        <iconify-icon icon="fluent-mdl2:back" class="shop-back-icon" style={{color:"black"}} onClick={handleBack} ></iconify-icon>
                        <h1 className="d-inline" style={{marginLeft:"3%"}}>Search results for "<h1 className="d-inline" style={{color:"#e96a0f"}}>{searchedString}</h1>"</h1>
                    </div>)}
                {localProducts.map((product,index)=>(
                    <AddProduct product={product} key={product.id} index={index} deleteProduct={deleteProduct}/>
                ))}
                {isAlertOpen && (<Alert
                    isCancel={true}
                    message="Are you sure you want to Delete the product ?"
                    onCancel={handleCancel}
                    onConfirm={handleConfirm}
                />)}
                {isDeleted && (<Alert
                    isCancel={false}
                    message="Successfully Deleted !"
                    onCancel={()=>{setDeleted(false)}}
                    onConfirm={()=>{setDeleted(false)}}
                />)}
            </div>
        </div>
        </div>
    )
}
export default Add;