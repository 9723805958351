import React, { useState } from "react";
import axios from "axios";
import Alert from "./utils/Alert";


function AddProduct(props) {
    const product = props.product;
    const isNew=props.isNew;
    const [newName, setNewName] = useState(product.name);
    const [newPrice, setPrice] = useState(product.price);
    const [newDescription, setDescription] = useState(product.description);
    const [newQuantiy, setQuantity] = useState(product.quantity);
    const [images, setImages] = useState(product.images);
    const [addImageStatus, setAddImage] = useState(true);
    const [newImage, setNewImage] = useState("");
    const [isUpdated, setUpdated] = useState(false);
    const [isAdded,setAdded]=useState(false);
    const [isEditingName,setEditingName]=useState(false);
    const [isEditingDesc,setEditingDesc]=useState(false);
    const [isEditingPrice,setEditingPrice]=useState(false);
    const [isEditingQuantity,setEditingQuantity]=useState(false);

    function base64ToFile(base64Data, filename, mimeType) {
        const byteCharacters = atob(base64Data);
        const byteArrays = [];
    
        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);
    
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
    
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
    
        const blob = new Blob(byteArrays, { type: mimeType });
        return new File([blob], filename, { type: mimeType });
    }
    

    function handleSubmit(e) {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        formData.append("id",product.id);
        for (let i = 0; i < images.length; i++) {
            const base64Link = images[i];
            const base64Data = base64Link.split(";base64,").pop();
            const filename = `file${i}.png`;
            const file = base64ToFile(base64Data, filename, "image/png");
            formData.append("images", file);
        }
        formData.append("pname",newName);
        formData.append("pdescription",newDescription);
        formData.append("price",newPrice);
        formData.append("pquantity",newQuantiy);
        if(isNew){
            axios.post("https://dsg-customs-backend.vercel.app/api/addProduct",formData,{
                headers:{
                    "Content-Type":"multipart/form-data"
                }
            })
            .then(response=>{
                props.setIsChange(true);
                setAdded(true);
                
            })
            handleReset();
        }
        else{
            axios.put("https://dsg-customs-backend.vercel.app/api/updateProduct", formData)
            .then((response) => {
                if(response.data==="updated"){
                    setUpdated(true);
                }
                
            })
            .catch(err => console.log(err))
        }
    }

    function handleReset() {
        setDescription(product.description);
        setNewName(product.name);
        setPrice(product.price);
        setQuantity(product.quantity);
        setImages(product.images);
    }

    function handleDelete() {
        props.deleteProduct(props.index);
    }

    function handleImageChange(e) {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = (event) => {
                const base64 = event.target.result;
                setNewImage(base64);
            };

            reader.readAsDataURL(file);
        }
    }

    function deleteImage(index) {
        setImages(prevImages => {
            const newImages = [...prevImages];
            newImages.splice(index, 1);
            return newImages;
        });
    }

    function addImage() {
        if (newImage) {
            setImages(prevImages => {
                const newImages = [...prevImages, newImage];
                return newImages;
            })
        }
        else {
            alert("Please choose an image before adding.");
        }
        setNewImage("");
        setAddImage(true);
    }
    

    return (
        <form onSubmit={handleSubmit}>
            <div className="row admin-product ">
                <div className="col-lg-5 col-md-5 col-sm-12 product-image row">
                    {images.map((image, index) => (
                        <div className="admin-product-img col-lg-4 col-sm-4" key={index}>
                            <img className="product-list-img" src={image}  alt="" />
                            <button type="button" className="delete-button" onClick={() => { deleteImage(index) }}> delete image <iconify-icon icon="ic:round-delete" style={{color: "white"}}></iconify-icon> </button>
                        </div>
                    ))}
                    {addImageStatus ? (<button type="button" className="add-img-btn" onClick={() => { setAddImage(false) }}> Add Image <iconify-icon icon="zondicons:add-outline" style={{color: "white",marginTop:"2%"}}/></button>) :
                        (<div className="add-img-open"><input type="file" name="newImage" onChange={handleImageChange} />
                            <button  type="button" onClick={addImage}>add Image<iconify-icon icon="zondicons:add-outline" style={{color: "white",paddingLeft:"3%"}}></iconify-icon></button>
                            <button  type="button" onClick={()=>{setAddImage(true)}} style={{marginLeft:"7%",backgroundColor:"#ff1100"}}>cancel</button>
                        </div>)}
                </div>
                
                <div className="col-lg-5 col-md-5 col-sm-12 inputs">
                    <label htmlFor={"#"+product.id+"pname"}>Name :</label>
                    {isEditingName ? (<div className="d-inline"><input id={product.id+"pname"} type="text" name="pname" onChange={(e) => { setNewName(e.target.value) }} value={newName} />
                    <iconify-icon class="admin-icons" icon="charm:tick" onClick={()=>{setEditingName(false)}} style={{color: "green"}} ></iconify-icon> </div>) : (<div className="d-inline" ><span>{newName}</span><iconify-icon class="admin-icons" onClick={()=>{setEditingName(true)}} icon="tabler:edit"></iconify-icon></div>) }
                    <br />
                    <label htmlFor={"#"+product.id+"description"}>Description :</label>
                    {isEditingDesc ? (<div className="d-inline"><textarea id={product.id+"description"} type="text" name="pdescription" onChange={(e) => { setDescription(e.target.value) }} value={newDescription} />
                    <iconify-icon class="admin-icons" icon="charm:tick" onClick={()=>{setEditingDesc(false)}} style={{color: "green"}} ></iconify-icon></div>) : (<div className="d-inline"><span>{newDescription}</span><iconify-icon class="admin-icons" onClick={()=>{setEditingDesc(true)}} icon="tabler:edit"></iconify-icon></div>) }
                    
                    <br />
                    <label htmlFor={"#"+product.id+"price"}>Price :</label>
                    {isEditingPrice ? (<div className="d-inline"> <input id={product.id+"price"} type="number" name="price"  onChange={(e) => { setPrice(e.target.value) }} value={newPrice} />
                    <iconify-icon class="admin-icons" icon="charm:tick" onClick={()=>{setEditingPrice(false)}} style={{color: "green"}} ></iconify-icon></div>) : (<div className="d-inline"><span>{newPrice}</span><iconify-icon class="admin-icons" onClick={()=>{setEditingPrice(true)}} icon="tabler:edit"></iconify-icon></div>) }
                    <br />
                    <label htmlFor={"#"+product.id+"quantity"}>Quantity :</label>
                    {isEditingQuantity ? (<div className="d-inline"><input id={product.id+"quantity"} type="number" name="pquantity"  onChange={(e) => { setQuantity(e.target.value) }} value={newQuantiy} />
                    <iconify-icon class="admin-icons" icon="charm:tick" onClick={()=>{setEditingQuantity(false)}} style={{color: "green"}} ></iconify-icon></div>) : (<div className="d-inline"><span>{newQuantiy}</span><iconify-icon class="admin-icons" onClick={()=>{setEditingQuantity(true)}} icon="tabler:edit"></iconify-icon></div>) }
                    
                </div>
                {isNew ? (<button className="delete-button main-btn main-btn-update m-auto" type="submit">Add product</button>) : (<div className="col-lg-2 col-md-2 col-sm-12">
                    <button className="delete-button main-btn main-btn-delete" type="button" onClick={handleDelete}>Delete<iconify-icon icon="ic:round-delete" style={{color: "white",height:"30%"}}></iconify-icon></button>
                    <button className="delete-button main-btn main-btn-reset" onClick={handleReset} type="reset" >Reset Changes</button>
                    <button className="delete-button main-btn main-btn-update " type="submit">Update Changes</button>
                </div>)}
                {isUpdated && (<Alert
                    isCancel={false}
                    message="Updated !"
                    onCancel={()=>{setUpdated(false)}}
                    onConfirm={()=>{setUpdated(false)}}
                />)}
                {isAdded && (<Alert
                    isCancel={false}
                    message="Product added !"
                    onCancel={()=>{setAdded(false)}}
                    onConfirm={()=>{setAdded(false)}}
                />)}
            </div>

        </form>
    )
}

export default AddProduct;