import React from 'react'
import w1 from "../Images/w1.jpg";
import w2 from "../Images/w2.jpg";
import w3 from "../Images/w3.jpg";
import { useEffect, useRef } from 'react';
import {AnimatePresence, motion, useInView,useAnimation, delay} from "framer-motion/dist/framer-motion";
import { useNavigate } from "react-router-dom";

function Banner(){
    const ref1=useRef(null);
    const navigate = useNavigate();
    const isInView=useInView(ref1,{once:true});
    const bannerControls=useAnimation();
    useEffect(()=>{
        if(isInView){
            bannerControls.start("visible");
        }
    })

    const bannerVariants={
        hidden:{
            opacity:0,
            y:"100vw"
        },
        visible:{
            opacity:1,
            y:0,
            transition:{
                type:"spring",
                duration:0.2,
                stiffness:50
            }
        }
    }
    const bannerVariants1={
        hidden:{
            opacity:0,
            y:"100vw"
        },
        visible:{
            opacity:1,
            y:0,
            transition:{
                type:"spring",
                duration:0.2,
                stiffness:50,
                delay:0.3
            }
        }
    }

    return(
        <section id="banner1">
            <div class="row prod-sec" ref={ref1}>
                <motion.div 
                    variants={bannerVariants}
                    initial="hidden"
                    animate={bannerControls}
                    id="banner"
                    className="col-lg-6 carousel slide h-100" data-bs-ride="carousel">
                    <div className="carousel-inner" >
                        <div className="carousel-item active">
                            <img src={w1} className="d-block w-100 img-product" alt="..."/>
                        </div>
                        <div className="carousel-item">
                            <img src={w2} className="d-block w-100 img-product" alt="..."/>
                        </div>
                        <div className="carousel-item">
                            <img src={w3} className="d-block w-100 img-product" alt="..."/>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#banner" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#banner" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </motion.div>

                <motion.div
                    variants={bannerVariants1}
                    initial="hidden"
                    animate={bannerControls}
                    class="col-lg-4 accent prod-desc wow fadeInUp" data-wow-delay="0.5s">
                    <br></br>
                    <p>
                        Looking for custom Volkswagen parts? DSG Custom has you covered. Whether you're looking for a new exhaust system, a set of wheels, or a complete body kit, we have what you need.
                    </p>
                    
                        <div class="link">
                            <a  onClick={()=>{navigate('/shop')}}>shop now
                                <iconify-icon icon="gridicons:arrow-right"></iconify-icon>
                            </a>
                        </div>
                </motion.div>
            </div>
        </section>
    );
}

export default Banner;
