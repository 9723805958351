import React from 'react'
import Icons from "./Icons";
function Footer(){
    return(
        <div class="container-fluid footer">
            <div class="section container">
                <div class="row">
                    <div class="col-lg-3 col-6 block">
                        <p>Kandasamy Nagar Palavakkam
                            <br/>
                            Chennai, Tamil Nadu 600041
                            <br/>
                            India
                        </p>
                    </div>
                    <div class="col-lg-3 col-6 block">
                        <p>
                            <a href="">FAQ</a>
                            <br/>
                            <a href="">Return Policy</a>
                            <br/>
                            <a href="">Shipping</a>
                        </p>
                    </div>
                    <div class="col-lg-3 col-6 block">
                        <p>
                            <a href="">Community</a>
                            <br/>
                            <a href="">Careers</a>
                            <br/>
                            <a href="">Wholesare</a>
                        </p>
                    </div>
                    <div class="col-lg-3 col-6 block">
                        <p>Stay connected</p>
                        <div class="footer-media">
                            <Icons/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;
