import React from 'react'
import hero from '../Images/hero.jpg'
import {motion} from "framer-motion/dist/framer-motion";
function Hero() {
    return (
        <motion.div 
        initial={{y:"100vh"}}
        animate={{y:0}}
        transition={{type:"tween",duration:0.4,delay:0}}
        class="hero-section">
            <div class="hero-image">
                <img src={hero} class="img" alt="dsg custom porsche"/>                                                                                                                                                                                                  
            </div>
        </motion.div>
    );
}
export default Hero;