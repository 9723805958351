import React from 'react'
function Carousel(props){
    const {id,images}=props;
    return(
        <div id={id} class="carousel h-100" data-bs-ride="carousal">
            <div class="carousel-indicators">
                <button type="button" data-bs-target={`#${id}`} data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target={`#${id}`} data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target={`#${id}`} data-bs-slide-to="2" aria-label="Slide 3"></button>
            </div>
            <div class="carousel-inner h-100">
                {images.map((image,index)=>{
                    return(
                        <div className={`carousel-item h-100  ${index===0 ? "active" :""}`} data-bs-interval="4000">
                            <img style={{cursor:"pointer"}} className="h-100" onClick={() => {window.location.href = 'https://checkout.stripe.dev/preview';}} src={image} alt="product image" />
                        </div>
                    )
                })}
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target={`#${id}`} data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target={`#${id}`} data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>
    )
}

export default Carousel;