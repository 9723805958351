import React from 'react'
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
function Login(props){
    const [ismsg,setMsg]=useState(false);
    const [ismsg2,setMsg2]=useState(false);
    const navigate=useNavigate();
    function handleSubmit(e){
        e.preventDefault();
        setMsg(false);
        setMsg2(false);
        const formData=new FormData(e.currentTarget);
        axios.post("https://dsg-customs-backend.vercel.app/api/login",formData)
        .then(response=>{
            console.log(response.data);
            if(response.data==="Matched"){
                props.set1(true);
                navigate("/add");
            }
            else if(response.data==="wrong"){
                setMsg(true);
            }
            else if(response.data==="user does not exist"){
                setMsg2(true);
            }
        })  

    }
    return(
        <div>
            {ismsg && (<h1>Worng password</h1>)}
            {ismsg2 && (<h1>User Does Not exist</h1>)}
            <form class="t_form" onSubmit={handleSubmit} >
                <div class="row mb-3">
                <label for="userName" class="col-sm-2 col-form-label">Email</label>
                <div class="col-sm-10">
                    <input type="text" name="inputUserName" class="form-control" id="userName" placeholder="username"/>
                </div>
                </div>
                <div class="row mb-3">
                <label for="inputPassword" class="col-sm-2 col-form-label">Password</label>
                <div class="col-sm-10">
                    <input type="password" name="inputPassword" class="form-control" id="inputPassword3"/>
                </div>
                </div>
                <button type="submit" class="btn btn-primary">Log in</button>
                <a style={{paddingLeft: "50%"}}  href="">forgot password?</a>
            </form>
        </div>
        
    )
}

export default Login;