import React,{useEffect, useRef, useState} from "react";
import logo from "../../Images/logo.png"
import Icons from "./Icons";
import { AnimatePresence, motion, spring } from "framer-motion/dist/framer-motion";
import { useNavigate,Link } from "react-router-dom";

function Navbar(props) {
    const [scroll, setScroll] = useState(false);
    const [height,setHeight]=useState(0);
    const [menuOpen,setMenu]=useState(false);
    const navigate=useNavigate();
    const ref=useRef(null);
    useEffect(() => {
        if(props.page==="home"){
            if(ref.current){
                setHeight(ref.current.clientHeight);
                window.addEventListener("scroll", () => {
                    setScroll(window.scrollY > height);
                })
            }
        }
        else{
            setScroll(true);
        }
        
    },[]);
    function handleClick(){
        setMenu(!menuOpen);
    }
    return (
        <nav className={"nav" + " " + (scroll ? " scrolled" : "")} ref={ref}>
            <div onClick={handleClick}>
                <iconify-icon class={"menu-open" + " " + (scroll ? "dark":"")} icon="material-symbols:menu" height="none" ></iconify-icon>
            </div>

            <div class="brand">
                <span><a href=""><img className={"logo"+" "+ (scroll ? "display":"brand-logo")} src={logo} alt="dsg customs"/></a></span>
            </div>
            <div className={"cart" + " " + (scroll ? "dark":"") }>
                <iconify-icon class="cart-icon" icon="mdi:cart" height="none" ></iconify-icon>
            </div>
            <div className={"menu"+(menuOpen ? " open":"")}>
                <iconify-icon onClick={handleClick} class="menu-close" icon="material-symbols:close-rounded" height="none" ></iconify-icon>
                <ul>
                    <motion.li className="nav-li" whileHover={{scale:1.3,originX:0}} transition={{type:'spring',stiffness:200}}><a href="" onClick={()=>{navigate('/home')}}>Home</a></motion.li>
                    <motion.li className="nav-li" whileHover={{scale:1.3,originX:0}} transition={{type:'spring',stiffness:200}}><a href="" onClick={()=>{navigate('/shop')}}>Collection</a></motion.li>
                    <motion.li className="nav-li" whileHover={{scale:1.3,originX:0}} transition={{type:'spring',stiffness:200}}><Link to={"/home/about-us"} > About us</Link></motion.li>
                    <motion.li className="nav-li" whileHover={{scale:1.3,originX:0}} transition={{type:'spring',stiffness:200}}><Link to={"/home/contact-us"}>Contact</Link></motion.li>
                </ul>
                <div class="media-menu">
                    <Icons/>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;