import './App.css';
import React from 'react'
import Home from './Pages/Home';
import Login from './Pages/Login';
import Add from './Pages/Add';
import EmailForm from './Pages/EmailForm';
import Products from './Pages/Products';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { useState} from 'react';

function App() {
  const [isLoggedIn,setLoggedIn]=useState(false);

  
  return (
    <div  className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/home' element={<Home/>} />
          <Route path="/" element={<Navigate to="/home" />} />
          <Route path='/home/:sectionId' element={<Home/>} />
          <Route path='/shop' element={<Products/>} />
          <Route path="/admin" element={<Login set1={setLoggedIn} />} />
          <Route path='/testing' element={<EmailForm/>} />
          {isLoggedIn && (<Route path="/add" element={<Add/>} />)}
          {isLoggedIn && (<Route path="/mailer" element={<EmailForm/>} />)}
          <Route path="*" element={<Navigate to="/home" />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
