import React from "react";

function Alert({ isCancel, message, onCancel, onConfirm }) {
    
    
    const modalStyle = {
        display:"block",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 9999,
    };

    const contentStyle = {
        backgroundColor: "#fff",
        padding: "20px",
        width: "300px",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    };

    return (
        <div style={modalStyle}>
            <div style={contentStyle}>
                <p>{message}</p>
                {isCancel && (<button onClick={onCancel}>Cancel</button>)}
                <button onClick={onConfirm}>Confirm</button>
            </div>
        </div>
    );
}

export default Alert;
