import React from 'react'
import { motion } from "framer-motion/dist/framer-motion";

function Contact(){
    return(
        <section id="contact-us">
            <div class="contact-body">
                <div class="container">
                    <div >
                        <div class="titles">
                            <h4 class="title">Contact Us</h4>
                        </div>
                        <h6 class="subtitle">If you've any queries feel free to contact us</h6>
                        <div className="row contents">
                            <div className="contact-form col-lg-6">
                                <form action="https://formsubmit.co/instantgroup7@gmail.com" method="POST">
                                    <div class="field">
                                        <label class="label">Name</label>
                                        <div class="control">
                                            <input class="contact-input" type="text" placeholder="Your Name" required="" />
                                        </div>
                                    </div>
                                    <div class="field">
                                        <label class="label">Email</label>
                                        <div class="control">
                                        <   input class="contact-input" type="text" placeholder="Email address" required="" />
                                        </div>
                                    </div>
                                    <div class="field">
                                        <label class="label">Phone Number</label>
                                        <div class="control">
                                            <input class="contact-input" type="text" placeholder="Phone number" required="" />
                                        </div>
                                    </div>
                                    <div class="field">
                                        <label class="label">Message</label>
                                        <div class="control">
                                            <textarea
                                                class="contact-input"
                                                type="text"
                                                placeholder="Type your message here..."
                                                required=""
                                            ></textarea>
                                        </div>
                                    </div>
                                    <div class="field">
                                        <label class="label"></label>
                                        <div class="control">
                                            <motion.input whileHover={{scale:1.1,textShadow:"0px 0px 8px rgb(255,255,255)"}} className="button" type="submit" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-lg-6">
                                <iframe
                                        width="100%"
                                        height="100%"
                                        frameborder="0"
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15552.436887936996!2d80.2534849!3d12.964862250000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525d15626cab39%3A0xe55c5559172fdc49!2sKandasamy%20Nagar%2C%20Palavakkam%2C%20Chennai%2C%20Tamil%20Nadu%20600041!5e0!3m2!1sen!2sin!4v1684912201388!5m2!1sen!2sin"
                                        allowfullscreen
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Contact;